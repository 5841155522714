import { useFetchingUtilityProgram } from '@/app/features/utility-programs/UtilityProgramEligibleDeviceEnrollmentFlowStep/useViewModel'
import Text from '@/components/Text/Text'
import ComboBox from '@/components/forms/combo-box'
import { Utility, UtilitySearchResult } from '@/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { throttle } from 'lodash'
import { fetchAuthenticated } from '@/utils/fetch'
import { CheckIcon, XIcon } from 'lucide-react'
import { Button } from '@/components'
import { match } from 'ts-pattern'
import { selectUtility } from '@/actions/utilities'
import { useProgramRequirements } from '@/app/hooks/useProgramRequirements'
import { searchableUtilityProgramsCollection } from '@/reducers/utilityPrograms'

const FormSchema = z.object({
  utility: z.object({ id: z.number(), name: z.string() }).array(),
})
enum EligibilityStatus {
  ELIGIBLE = 'ELIGIBLE',
  INELIGIBLE = 'INELIGIBLE',
  NONE = 'NONE',
}

function useViewModel() {
  const { id: utilityProgramId } = useParams<{ id: string }>()
  const { data: utilityPrograms } =
    searchableUtilityProgramsCollection.useFetch({
      path: `utility_programs/${utilityProgramId}`,
    })
  const utilityProgram = utilityPrograms?.[0]

  const initialSelectedUtility = useAppSelector(
    (state) => state.utilities.selectedUtility,
  )
  const initialValue = useMemo(() => {
    if (!initialSelectedUtility) {
      return
    }
    return {
      id: initialSelectedUtility.id,
      name: initialSelectedUtility.name,
    }
  }, [initialSelectedUtility])
  const [selectedUtility, setSelectedUtility] = useState<
    { id: number; name: string } | undefined
  >(initialValue)

  const eligibilityStatus: EligibilityStatus = useMemo(() => {
    if (!selectedUtility) {
      return EligibilityStatus.NONE
    }
    const isSelectedUtilityEligible = utilityProgram.utility_ids.includes(
      selectedUtility.id,
    )
    return isSelectedUtilityEligible
      ? EligibilityStatus.ELIGIBLE
      : EligibilityStatus.INELIGIBLE
  }, [selectedUtility, utilityProgram])
  const [utilities, setUtilities] = useState<Utility[]>([])
  const options = useMemo(
    () => (utilities ?? []).map((u) => ({ id: u.id, name: u.name })),
    [utilities],
  )
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: 'onTouched',
  })
  useEffect(() => {
    const { unsubscribe } = form.watch((value) => {
      const utility = value.utility as unknown as
        | { id: number; name: string }
        | undefined
      setSelectedUtility(utility)
    })
    return () => unsubscribe()
  }, [form.watch])
  const onQueryChange = useCallback(
    throttle(async (value: string) => {
      const searchParams = new URLSearchParams([['search', value]])
      const resp = await fetchAuthenticated(
        `/search-utilities`,
        undefined,
        searchParams,
      )
      const json = await resp.json()
      setUtilities(json as Utility[])
    }, 500),
    [],
  )
  const dispatch = useAppDispatch()
  const { saveForLater, routeToNextStep } =
    useProgramRequirements(utilityProgram)
  const onNextClick = () => {
    if (!selectedUtility?.id) {
      return
    }
    dispatch(selectUtility({ utility_id: selectedUtility.id }))
    if (initialSelectedUtility) {
      routeToNextStep()
    }
  }
  const onSeeOtherPrograms = () => {
    saveForLater()
  }
  return {
    form,
    options,
    initialValue,
    eligibilityStatus,
    onQueryChange,
    selectedUtility,
    onNextClick,
    onSeeOtherPrograms,
  }
}
export default function UtilitySelection() {
  const {
    form,
    options,
    initialValue,
    eligibilityStatus,
    onQueryChange,
    onNextClick,
    onSeeOtherPrograms,
  } = useViewModel()
  const { register, setValue, getValues } = form
  const comboBoxIcon = match(eligibilityStatus)
    .with(EligibilityStatus.ELIGIBLE, () => (
      <CheckIcon
        size={22}
        className="rounded-full p-1 bg-themed-green-900 text-themed-white"
      />
    ))
    .with(EligibilityStatus.INELIGIBLE, () => (
      <XIcon
        size={22}
        className="rounded-full p-1 bg-themed-error text-themed-white"
      />
    ))
    .with(EligibilityStatus.NONE, () => null)
    .exhaustive()
  return (
    <div className="flex justify-between flex-col gap-8 h-full">
      <section className="w-full flex flex-col gap-2">
        <Text variant="h3">Who is your utility provider?</Text>
        <Text variant="body2">
          Select your utility provider to integrate your exact electricity
          rates.
        </Text>
        <ComboBox
          initialValue={initialValue}
          name="utility"
          label="Select Provider"
          options={options}
          setValue={setValue}
          multiple={false}
          getValues={getValues}
          onQueryChange={onQueryChange}
          register={register}
          autoFocus={true}
          required={true}
          icon={comboBoxIcon}
        />
        {match(eligibilityStatus)
          .with(EligibilityStatus.ELIGIBLE, () => (
            <Text variant="body3" className="text-themed-green-900">
              Utility provider is eligible
            </Text>
          ))
          .with(EligibilityStatus.INELIGIBLE, () => (
            <>
              <Text variant="body3" className="text-themed-error">
                Utility provider is not eligible
              </Text>
              <Button
                id="other-programs"
                variant="navigation"
                className="!text-themed-blue-500 !p-0 underline"
                onClick={onSeeOtherPrograms}
              >
                See other utility programs
              </Button>
            </>
          ))
          .with(EligibilityStatus.NONE, () => null)
          .exhaustive()}
      </section>
      <Button
        id={'utility-select-next'}
        disabled={eligibilityStatus !== EligibilityStatus.ELIGIBLE}
        onClick={onNextClick}
      >
        Next
      </Button>
    </div>
  )
}
